import React from "react";
import '../../css/gallery.css';

const CardBTS = (props) => {
    return (
        <div className="card-bts">
            <img className="card-bts-image" src={props.imageSrc} alt="" />
            <div className="card-bts-text">
                <p className="card-bts-date">{props.date}</p>
                <p className="card-bts-description">{props.description}</p>
            </div>
        </div>
    );
};

export default CardBTS;
