import React from "react";
import "../css/style.css"; 
import "../css/media-query.css"; 
import qrcode from '../assets/qrcode.png';
import download from '../assets/download.png';
import polygon from '../assets/polygon.png';
import polygondark from '../assets/Polygondark.png';


const SystemRequirementsModal = ({ isOpen, closeModal }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={closeModal}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="polygon-background">
          {/* Overlaying both polygons */}
          <img src={polygon} alt="Polygon Shape" className="polygon" />
          <img src={polygondark} alt="Dark Polygon Shape" className="polygon-dark" />
        </div>
        <div className="qr-code">
          <img src={qrcode} alt="QR Code" />
        </div>
        <div className="modal-text">
            <h3>Sistem yang dibutuhkan :</h3>
            <ul>
              <li>CPU: Intel Core 2 Quad</li>
              <li>VIDEO CARD: NVIDIA 9800 GT 1GB (DX 10, 10.1, 11)</li>
              <li>RAM: 8GB</li>
              <li>OS: Windows 10 64 Bit, Windows 8.1 64 Bit, Windows 8 64 Bit</li>
              <li>FREE DISK SPACE: 72 GB</li>
            </ul>
            <button className="download-button">
              Unduh Sekarang <img src={download} alt="Download" />
            </button>
        </div>
      </div>
    </div>
  );
};

export default SystemRequirementsModal;
