import React from "react";

const VideoCard = (props) => {
    return (
        <div className="video-card">
            {/* Video with controls */}
            <video 
                className="video-element" 
                src={props.vidSrc} 
                controls 
                width="50%" 
                autoPlay 
                loop 
                muted
            >
                Your browser does not support the video tag.
            </video>
            <div className="desc">
                <h4>{props.heading}</h4>
                <p>{props.text}</p>
            </div>
        </div>
    );
};

export default VideoCard;
