import React from 'react';
import '../../css/tentang-section.css';
import Moon from '../../assets/moon.png';

const TentangCard = ({ title, heading, headingSpan, tagline1, tagline2, tagline3, description, image1, image2, reverse }) => {
  return (
    <div className={`tentang-card ${reverse ? 'reverse' : ''}`}>
      <div className="text-content">
        <h3>{title}</h3>
        <h1>{heading}<span>{headingSpan}</span></h1>
        <h2>{tagline1}</h2>
        <h2>{tagline2}</h2>
        <h2>{tagline3}</h2>
        <p>{description}</p>
      </div>
      <div className="images-container">
        <img className="image1" src={image1} alt="Image 1" />
        <img className="image2" src={image2} alt="Image 2" />
      </div>
      <img src={Moon} alt="Moon Icon" className="moon top-left" />
      <img src={Moon} alt="Moon Icon" className="moon top-right" />
      <img src={Moon} alt="Moon Icon" className="moon bottom-left" />
      <img src={Moon} alt="Moon Icon" className="moon bottom-right" />
    </div>
  );
};

export default TentangCard;
