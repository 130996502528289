import React from "react";
import vid1 from "../../assets-gallery/vid1.mp4";
import vid2 from "../../assets-gallery/vid2.mp4";
import vid3 from "../../assets-gallery/vid3.mp4";
import vid4 from "../../assets-gallery/vid4.mp4";
import vid5 from "../../assets-gallery/vid5.mp4";
import '../../css/gallery.css';
import '../../css/gallery-media.css';
import VideoCard from "./video-card";

const Video = () => {
    return (
        <div className="video-container">
            <VideoCard 
                vidSrc={vid1} 
                heading="Proses Login dan Masuk ke Permainan" 
                text="Pemain memulai perjalanan dengan login ke sistem, disambut oleh tampilan antarmuka yang ramah pengguna sebelum memasuki dunia virtual Fakultas Teknik UNSRAT." 
            />
            <VideoCard
                vidSrc={vid2} 
                heading="Menelusuri Dekanat dan Area Fakultas Teknik" 
                text="Pemain dapat menjelajahi area dekanat, berjalan di lorong-lorong, dan menaiki tangga untuk mendapatkan gambaran menyeluruh tentang suasana Fakultas Teknik." 
            />
            <VideoCard 
                vidSrc={vid4} 
                heading="Multiplayer" 
                text="Pemain dapat berinteraksi dengan teman-teman, berjalan bersama, berdiskusi, dan menjelajahi kampus secara kolaboratif." 
            />
            <VideoCard
                vidSrc={vid3} 
                heading="Lingkungan Fakultas Teknik" 
                text="Pemain dapat menjelajahi lingkungan sekitar Fakultas Teknik, menikmati pemandangan gedung-gedung fakultas yang asri dan pepohonan hijau yang memberikan suasana sejuk dan nyaman." 
            />
            <VideoCard 
                vidSrc={vid5} 
                heading="Eksplorasi Area Luar Fakultas Teknik" 
                text="Pemain dapat berjalan-jalan di sekitar area luar Fakultas Teknik, menikmati suasana asri dengan pepohonan rindang dan lingkungan yang tertata rapi, menciptakan pengalaman eksplorasi yang menyenangkan." 
            />
        </div>
    );
};

export default Video;
