import React from 'react';
import Title from './general-components/title';
import CharaBox from './select-your-chara-components/chara-box';
import maleChara from '../assets/male-chara.png';
import femaleChara from '../assets/female-chara.png';
import '../css/style.css'
import '../css/media-query.css'

const SelectYourChara = () => {
  return (
    <section id="select-your-chara">
      <Title name="Pilih Karaktermu" />
      <div className="chara-container">
        <CharaBox
          charaType="male"
          name="Hosea"
          imgSrc={maleChara}
          altText="Hosea"
          desc="Sebagai inovator cerdas, Hosea memanfaatkan teknologi dengan presisi untuk mengeksplorasi setiap sudut Techoverse. Pilih Hosea jika Anda ingin menjelajahi laboratorium canggih dan fasilitas teknik UNSRAT dengan pendekatan yang analitis dan penuh perhitungan."
        />
        <CharaBox
          charaType="female"
          name="Angela"
          imgSrc={femaleChara}
          altText="Angela"
          desc="Angela adalah ahli dalam interaksi dinamis di Techoverse, selalu siap menghadapi tantangan dengan cepat. Dengan kemampuan beradaptasinya, Angela cocok bagi Anda yang ingin dengan bebas menjelajahi setiap departemen dan fasilitas teknik UNSRAT dengan cara yang intuitif dan spontan."
        />
      </div>
    </section>
  );
};

export default SelectYourChara;