import React from 'react';
import backgroundVideo from '../assets/background-hero.mp4'; 
import '../css/style.css';
import '../css/media-query.css';

const Hero = () => {
  return (
    <section id="hero">
      <div className="container">
        <div className="container-hero">
          {/* Video Background */}
          <video autoPlay loop muted playsInline className="background-video">
            <source src={backgroundVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          {/* Content Over Video */}
          <h1>Selamat Datang di, <span>Techoverse</span></h1>
          <p>Techoverse: Eksplorasi keindahan dari Kampus Fakultas Teknik UNSRAT secara virtual.</p>
          <button className="explore">Jelajahi</button>
        </div>
      </div>
    </section>
  );
};

export default Hero;
