import React from 'react';
import foto1 from "../../assets-gallery/foto1.png";
import foto2 from "../../assets-gallery/foto2.png";
import foto3 from "../../assets-gallery/foto3.png";
import foto4 from "../../assets-gallery/foto4.png";
import foto5 from "../../assets-gallery/foto5.png";
import foto6 from "../../assets-gallery/foto6.png";
import '../../css/gallery.css';

const Foto = () => {
  return (
    <div className="card-container">
      <div className="first-card-container">
        <div className="first-photo-container">
          <img className="photos" src={foto2} alt="Photo" />
          <img className="photos second" src={foto1} alt="Photo" />
        </div>
        <p><span>Kantor Dekanat Fakultas Teknik UNSRAT</span> <br />
        Pemain disambut oleh suasana profesional dan modern di kantor dekanat, memberikan kesan pertama yang formal dan ramah.</p>
      </div>
      <div className="second-card-container">
        <div className="second-photo-container">
          <img className="photos" src={foto3} alt="Photo" />
        </div>
        <p><span>Eksplorasi Laboratorium</span>
        Pemain menjelajahi laboratorium canggih dengan peralatan modern, mendapatkan informasi interaktif mengenai fungsinya.</p>
      </div>
      <div className="third-card-container slider">
        <img className="photos first" src={foto5} alt="Photo" />
        <img className="photos second" src={foto4} alt="Photo" />
        <img className="photos third" src={foto6} alt="Photo" />
      </div>
    </div>
  );
};

export default Foto;
