import React, { useState } from "react";
import CardTimKami from "./cards";
import '../../css/tim-kami.css';
import '../../css/tim-kami-media.css';

const SliderTimKami = ({ cardsData }) => {
    const [activeYear, setActiveYear] = useState(2023);
  
    const handleYearChange = (year) => {
      setActiveYear(year);
    };
  
    return (
      <div className="slider-tim-kami">
        {/* Diamond-shaped year selectors */}
        <div className="year-selector">
          <div 
            className={`diamond ${activeYear === 2023 ? 'active' : ''}`} 
            onClick={() => handleYearChange(2023)}
          >
            <span>2023</span>
          </div>
          <div 
            className={`diamond ${activeYear === 2024 ? 'active' : ''}`} 
            onClick={() => handleYearChange(2024)}
          >
            <span>2024</span>
          </div>
        </div>
  
        {/* Cards displayed based on active year */}
        <div className="slider-content">
          {cardsData[activeYear].map((card, index) => (
            <CardTimKami
              key={index}
              image={card.image}
              name={card.name}
              title={card.title}
              quote={card.quote}
            />
          ))}
        </div>
      </div>
    );
  };
  
  export default SliderTimKami;