import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/global.css';
import './css/global-media-query.css';
import App from './pages/home';

ReactDOM.render(
  <App />, document.getElementById('root')
)

