import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import unityLogo from '../assets/unity-logo.png';
import techoverseLogo from '../assets/techoverse-logo.png';
import SystemRequirementsModal from "./system-requirements"; 

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="navbar">
      <header className="d-flex flex-wrap align-items-center justify-content-between py-3 mb-4 box-header">
        <div className="logo-container d-flex align-items-center">
          <NavLink to="/" className="unity-logo d-inline-flex link-body-emphasis text-decoration-none me-2">
            <img src={unityLogo} alt="Unity Logo" />
          </NavLink>
          <NavLink to="/" className="techoverse-logo d-inline-flex link-body-emphasis text-decoration-none">
            <img src={techoverseLogo} alt="Techoverse Logo" />
          </NavLink>
        </div>

        <div className="menu-icon" onClick={toggleMenu}>
          <span></span>
          <span></span>
          <span></span>
        </div>

        <ul className={`nav col-12 col-md-auto mb-2 justify-content-center ${menuOpen ? 'open' : ''}`}>
          <li>
            <NavLink to="/" className="nav-link px-2" activeClassName="active">Beranda</NavLink>
          </li>
          <li>
            <NavLink to="/patch-notes" className="nav-link px-2" activeClassName="active">Catatan Patch</NavLink>
          </li>
          <li>
            <NavLink to="/gallery" className="nav-link px-2" activeClassName="active">Galeri</NavLink>
          </li>
          <li>
            <NavLink to="/about" className="nav-link px-2" activeClassName="active">Tim Kami</NavLink>
          </li>

          <li className="play-button-mobile">
            <button type="button" onClick={openModal}>Mainkan</button>
          </li>
        </ul>
      </header>

      {/* Modal for system requirements */}
      <SystemRequirementsModal isOpen={modalOpen} closeModal={closeModal} />
    </div>
  );
};

export default Navbar;
