import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "../components/navbar";
import Footer from "../components/footer"; 
import Hero from "../components/hero";
import Basics from "../components/basics";
import AboutTechoverse from "../components/about-techoverse";
import SelectYourChara from "../components/select-your-chara";
import JoinTheVerse from "../components/join-the-verse";
import Gallery from "../pages/gallery";
import '../css/style.css';
import '../css/media-query.css';
import TimKami from "./tim-kami";
import PatchNotes from "./patch-notes";
import TentangKami from "../components/tentang-kami";

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route 
          path="/" 
          element={
            <>
              <Hero />
              <Basics />
              <AboutTechoverse />
              <SelectYourChara />
              <JoinTheVerse />
            </>
          } 
        />
        
        <Route path="/gallery/*" element={<Gallery />} />
        <Route path="/about" element={<TimKami />} />
        <Route path="/tentang-kami" element={<TentangKami />} />
        <Route path="/patch-notes" element={<PatchNotes />} />

      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
