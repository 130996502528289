import React from "react";

const Header = (props) => {
  return (
    <div className="header-gallery">
      <img src={props.backgroundImage} alt="Header" />
      <div className="header-text">
        <h1>{props.title}</h1>
        <p>{props.description}</p>
      </div>
    </div>
  );
};

export default Header;
