import React from "react";
import '../../css/tim-kami.css';
import '../../css/tim-kami-media.css';

const CardTimKami = ({ image, name, title, quote }) => {
  return (
    <div className="card-tim-kami">
      <img src={image} alt={name} className="card-image" />
      <h3 className="card-name">{name}</h3>
      <p className="card-title">{title}</p>
    </div>
  );
};

export default CardTimKami;
