import React from 'react';
import joinImg from '../assets/join-the-verse.jpg';
import lineJoin from '../assets/line-join.png';
import '../css/style.css'
import '../css/media-query.css'

const JoinTheVerse = () => {
  return (
    <section id="join-the-verse">
      <div className="container-join">
        <h1>AYO<br /><span>BERGABUNG</span></h1>
        <div className="line-join">
          <img src={lineJoin} alt="Line Join" />
        </div>
        <button className="play-now">Main Sekarang</button>
        <button className="tentang-kami" onClick={() => window.location.href='/tentang-kami'}>
        Tentang Kami
        </button>
        <div className="join-img">
          <img src={joinImg} alt="Join The Verse" />
        </div>
      </div>
    </section>
  );
};

export default JoinTheVerse;