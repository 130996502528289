import React from "react";
import CardBTS from "./cardBTS";
import picture from "../../assets-gallery/picture.png";
import '../../css/gallery.css';
import '../../css/gallery-media.css';

const BTS = () => {
    return (
        <div className="bts-container">
            <CardBTS 
                imageSrc={picture} 
                date="Rabu, 17 November 2004" 
                description="Temukan berbagai tempat menarik untuk mengembangkan imajinasi anda dalam petualangan virtual ini!" 
                />
            <CardBTS 
                imageSrc={picture} 
                date="Rabu, 17 November 2004" 
                description="Hidupkan suasana dengan menjelajahi berbagai tempat inovatif!" 
                />
            <CardBTS 
                imageSrc={picture} 
                date="Rabu, 17 November 2004" 
                description="Eksplore berbagai tempat untuk mengenal fakultas teknik lebih jauh!" 
                />
            <CardBTS 
                imageSrc={picture} 
                date="Rabu, 17 November 2004" 
                description="Rasakan indahnya tempat-tempat futuristik di fakultas teknik" 
                />
            <CardBTS 
                imageSrc={picture} 
                date="Rabu, 17 November 2004" 
                description="Nikmati eksplorasi anda dengan pemain lainnya!" 
                />
            <CardBTS 
                imageSrc={picture} 
                date="Rabu, 17 November 2004" 
                description="Nikmati berbagai keindahan alat canggih di lab IT" 
                />
            <CardBTS 
                imageSrc={picture} 
                date="Rabu, 17 November 2004" 
                description="Kenali fakultas teknik unsrat tanpa harus mengunjungi-nya langsung!" 
                />
            <CardBTS 
                imageSrc={picture} 
                date="Rabu, 17 November 2004" 
                description="Temukan berbagai tempat menarik untuk anda ketahui hanya dalam suatu tempat!" 
                />
            <CardBTS 
                imageSrc={picture} 
                date="Rabu, 17 November 2004" 
                description="Pelajari berbagai fasilitas lainnya untuk anda ketahui!" 
                />
        </div>
    );
};

export default BTS;
