import { NavLink, useLocation } from "react-router-dom";
import '../../css/gallery.css'

const NavLinks = () => {
    return (
        <div className="techoverse">
            <h1>Techoverse</h1>
            <nav>
      <NavLink to="/gallery/foto" activeClassName="active">Foto</NavLink>
      <p>|</p> 
      <NavLink to="/gallery/video" activeClassName="active">Video</NavLink>
      {/* <p>|</p> */}
      {/* <NavLink to="/gallery/bts" activeClassName="active">BTS</NavLink> */}
    </nav>
        </div>
    );
};

export default NavLinks;
