import React from 'react';

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
    // Create an array with 5 elements to represent 5 pages
    const pages = Array.from({ length: 5 }, (_, index) => index + 1);

    const handlePrev = () => {
        if (currentPage > 1) {
            onPageChange(currentPage - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            onPageChange(currentPage + 1);
        }
    };

    return (
        <div className="pagination">
            <span 
                className="pagination-arrow" 
                onClick={handlePrev}
                style={{ cursor: currentPage > 1 ? 'pointer' : 'default' }}
            >
                ‹
            </span>
            {pages.map(page => (
                <span
                    key={page}
                    className={`pagination-dot ${currentPage === page ? 'active' : ''}`}
                    onClick={() => onPageChange(page)}
                    style={{ display: totalPages >= page ? 'inline-block' : 'none' }}
                ></span>
            ))}
            <span 
                className="pagination-arrow" 
                onClick={handleNext}
                style={{ cursor: currentPage < totalPages ? 'pointer' : 'default' }}
            >
                ›
            </span>
        </div>
    );
};

export default Pagination;
