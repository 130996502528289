import React from 'react';

const PatchCard = ({ patch }) => {
    return (
        <div className="patch-card">
            <img src={patch.imgSrc} alt={patch.title} />
            <div className="patch-details">
                <p className="patch-date"><span>Pembaharuan Permainan</span> | {patch.date}</p>
                <h3 className="patch-title">Techoverse {patch.title}</h3>
                <p className="patch-description">{patch.description}</p>
            </div>
        </div>
    );
};

export default PatchCard;
