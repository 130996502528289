import React from 'react';
import Header from "../components/general-components/header";
import background from "../assets-tentang-kami/background.jpg";
import TechoverseLogo from "../assets-tentang-kami/techoverse-logo.png";
import TentangCard from './tentang-kami-components/tentang-card';
import tentangImage1 from '../assets-tentang-kami/tentang-image1.png';
import tentangImage2 from '../assets-tentang-kami/tentang-image2.png';
import '../css/tentang-section.css'

const TentangKami = () => {
  return (
    <div>
      <Header 
        backgroundImage={background} 
        title="Tentang Kami" 
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit." 
      />
      <div className="techoverse-logo-container">
        <img className="techoverse-logo" src={TechoverseLogo} alt="Logo Techoverse" />
      </div>

      <div className="intro-text">
        <h1>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vulputate 
          odio sit amet lacus accumsan, in aliquet dolor congue.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vulputate 
          odio sit amet lacus accumsan, in aliquet dolor congue.
        </p>
      </div> 

    <div className="tentang-cards">
     {/* Section 01 */}
     <TentangCard 
        title="ABOUT US - 01"
        heading="Perkenalkan"
        headingSpan=" Techoverse."
        tagline1="digital." 
        tagline2="innovative." 
        tagline3="creative."
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vulputate odio sit amet lacus accumsan, in aliquet dolor congue."
        image1={tentangImage1}
        image2={tentangImage2}
        reverse={true} 
      />

      {/* Section 02 */}
      <TentangCard 
        title="ABOUT US - 02"
        heading="Perkenalkan"
        headingSpan=" Techoverse."
        tagline1="digital." 
        tagline2="innovative." 
        tagline3="creative."
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut vulputate odio sit amet lacus accumsan, in aliquet dolor congue."
        image1={tentangImage1}
        image2={tentangImage2}
        reverse={false}
      />
      </div>
    </div>
  );
};

export default TentangKami;
