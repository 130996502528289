import React from "react";
import '../css/tim-kami.css'
import Header from "../components/general-components/header";
import background from '../assets-tim-kami/background.png'
import SliderTimKami from "../components/tim-kami-components/slider";
import XaveSenduk from '../assets-tim-kami/xave-senduk.png'
import DanielSampe from '../assets-tim-kami/daniel-sampe.png'
import NyomanDeva from '../assets-tim-kami/nyoman-deva.webp'
import VincentManemi from '../assets-tim-kami/vincent-manemi.webp'
import FidelWowiling from '../assets-tim-kami/fidel-wowiling.webp'
import PriskilaSupit from '../assets-tim-kami/priskila-supit.png'
import DaffaFiat from '../assets-tim-kami/daffa-fiat.webp'
import NathanaelMiracle from '../assets-tim-kami/nathanael-miracle.webp'
import VaniaRampo from '../assets-tim-kami/vania-rampo.webp'
import AlfonsusMasakke from '../assets-tim-kami/alfonsus-masakke.webp'
import RomanBojoh from '../assets-tim-kami/roman-bojoh.webp'
import MarfoKapoh from '../assets-tim-kami/marfo-kapoh.png'
import ArielWalandouw from '../assets-tim-kami/ariel-walandouw.png'
import RichardManampiring from '../assets-tim-kami/richard-manampiring.png'
import DelonJutan from '../assets-tim-kami/delon-jutan.png'
import Fidel from '../assets-tim-kami/Fidel Wowiling_Development Lead.png'
import Alex from '../assets-tim-kami/Alexandrio Maleteng_Unity Engineer.png'
import Claisty from '../assets-tim-kami/Claisty Umboh_3D Environment Artist.png'
import Delon from '../assets-tim-kami/Delon Jutan_3D Environment Artist.png'
import Eden from '../assets-tim-kami/Eden Rompas_UNITY Engineer.jpg'
import Edward from '../assets-tim-kami/Edward Benedict_Unity Engineer.png'
import Elsa from '../assets-tim-kami/Elsa Siwy_UI Designer.jpg'
import Nathanael from '../assets-tim-kami/Nathanael Miracle_3D Character Artist.png'
import Priskila from '../assets-tim-kami/Priskila Supit_3D Environment Artist.png'
import '../css/tim-kami-media.css';

const cardsData = {
    2023: [
      { image: XaveSenduk, name: 'Fransiscus Xaverius Senduk', title: 'Senior Development Lead'},
      { image: DanielSampe, name: 'Daniel T. A. Sampe', title: 'Development Lead'},
      { image: NyomanDeva, name: 'I Nyoman Suwira Deva', title: 'UI Designer'},
      { image: VincentManemi, name: 'Vincent Bastian Manemi', title: 'Senior Developer'},
      { image: FidelWowiling, name: 'Fidel Jeremy Wowiling', title: 'Senior Developer'},
      { image: PriskilaSupit, name: 'Priskila Supit', title: '3D Artist'},
      { image: DaffaFiat, name: 'Daffa Nur Fiat', title: 'Unity Developer'},
      { image: NathanaelMiracle, name: 'Nathanael Brylliant Miracle', title: 'UI Designer'},
      { image: VaniaRampo, name: 'Vania Yori Rampo', title: '3D Artist'},
      { image: AlfonsusMasakke, name: 'Alfonsus Marco Masakke', title: 'Unity Developer'},
      { image: RomanBojoh, name: 'Roman Bojoh', title: 'Unity Developer'},
      { image: MarfoKapoh, name: 'Marfo Kapoh', title: 'Unity Developer'},
      { image: ArielWalandouw, name: 'Ariel Walandouw', title: 'Fullstack Web Developer'},
      { image: RichardManampiring, name: 'Richard Manampiring', title: 'Fullstack Web Developer'},
      { image: DelonJutan, name: 'Delon Jutan', title: '3D Artist'}
    ],
    2024: [
      { image: Fidel, name: 'Fidel Wowiling', title: 'Development Lead'},
      { image: Elsa, name: 'Elsa Siwy', title: 'UI Designer'},
      { image: Nathanael, name: 'Nathanael Miracle', title: '3D Character Artist'},
      { image: Alex, name: 'Alexandrio Maleteng', title: 'Unity Engineer'},
      { image: Eden, name: 'Eden Rompas', title: 'Unity Engineer'},
      { image: Edward, name: 'Edward Benedict', title: 'Unity Engineer'},
      { image: Claisty, name: 'Claisty Umboh', title: '3D Environment Artist'},
      { image: Delon, name: 'Delon Jutan', title: '3D Environment Artist'},
      { image: Priskila, name: 'Priskila Supit', title: '3D Environment Artist'}
    ],
};
  
  const TimKami = () => {
    return (
      <div className="tim-kami">
        <Header 
          backgroundImage={background} 
          title="Tim Kami" 
          description="Para Talent digital terbaik! Dibalik Sukses-nya lingkungan Fakultas Teknik virtual.
" 
        />
        
        <SliderTimKami cardsData={cardsData} />
      </div>
    );
  };
  
  export default TimKami;