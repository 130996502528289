import React from 'react';
import Card from './about-techoverse-component/card'; 
import xIcon from '../assets/X.png';
import oIcon from '../assets/O.png';
import triangleIcon from '../assets/triangle.png';
import '../css/style.css'
import '../css/media-query.css'

const AboutTechoverse = () => {
  return (
    <section id="about-techoverse">
      <div className="container-about">
        <Card
          title="Tentang"
          span="Techoverse"
          content="Di sini, Anda akan menemukan berbagai fitur menarik dan interaktif yang dirancang untuk memperkenalkan Anda pada fakultas Teknik UNSRAT secara lebih mendalam."
          icon={xIcon}
          iconAlt="X Icon"
          iconClassName="icon"
        />
        <Card
          title="Dunia Virtual"
          span="Inovatif"
          content="Techoverse adalah dunia virtual inovatif yang menawarkan pengalaman unik dalam menjelajahi Fakultas Teknik Universitas Sam Ratulangi (UNSRAT). Melalui lingkungan digital yang interaktif, Anda bisa mengeksplorasi laboratorium modern dengan peralatan canggih, serta berbagai fasilitas kampus yang mengagumkan."
          icon={oIcon}
          iconAlt="O Icon"
          iconClassName="icon-left"
        />
        <Card
          title="Jelajahi"
          span="Bersama-sama"
          content="Dengan fitur multiplayer, Anda dapat berkolaborasi dan menjelajahi kampus teknik UNSRAT bersama teman-teman Anda, menikmati pengalaman virtual yang penuh daya tarik dan tanpa batasan."
          icon={triangleIcon}
          iconAlt="Triangle Icon"
          iconClassName="icon"
        />
      </div>
    </section>
  );
};

export default AboutTechoverse;