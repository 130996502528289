import React from "react";
import { Route, Routes, Navigate } from 'react-router-dom'; 
import '../css/gallery.css';
import '../css/gallery-media.css';
import Video from "../components/gallery-components/video";
import BTS from "../components/gallery-components/bts";
import NavLinks from "../components/gallery-components/navlinks";
import Foto from "../components/gallery-components/foto";
import Header from "../components/general-components/header";
import background from '../assets-gallery/background.jpg'

const Gallery = () => {
  return (
    <div className="gallery-container">
      <Header 
        backgroundImage={background} 
        title="Galeri" 
        description="Jelajahi berbagai Hal Menarik disini!" 
      />
      <NavLinks />
      <Routes>
        {/* Redirect /gallery to /gallery/foto */}
        <Route path="/" element={<Navigate to="foto" replace />} />
        <Route path="foto" element={<Foto />} />
        <Route path="video" element={<Video />} />
        <Route path="bts" element={<BTS />} />
      </Routes>
    </div>
  );
};

export default Gallery;
