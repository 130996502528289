import React, { useState } from "react";
import Header from "../components/general-components/header";
import PatchCard from "../components/patch-notes-components/patch-card";
import Pagination from "../components/patch-notes-components/pagination";
import '../css/patch-notes.css';
import background from '../assets-gallery/background.jpg';

// Set patchData to an empty array initially
const patchData = [];

const PatchNotes = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 6;
    const totalPages = Math.ceil(patchData.length / itemsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const currentItems = patchData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <div className="patch-container">
            <div className="header">
                <Header 
                    backgroundImage={background} 
                    title="Catatan Patch" 
                    description="Berikut berbagai pembaruan untuk memperkaya fitur techoverse" 
                />
            </div>

            <div className="patch-content">
                {patchData.length > 0 ? (
                    currentItems.map(patch => (
                        <PatchCard key={patch.id} patch={patch} />
                    ))
                ) : (
                    <p className="no-updates-message">Belum ada pembaruan</p>
                )}
            </div>

            {patchData.length > 0 && (
                <Pagination 
                    totalPages={totalPages} 
                    currentPage={currentPage} 
                    onPageChange={handlePageChange} 
                />
            )}
        </div>
    );
};

export default PatchNotes;
